<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<div
			v-if="!$route.params.distributorId"
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					v-if="admin.includes(this.$user.details().type) && $can('orders', 'create') && !['OrdersPickup', 'ManualOrdersLogs'].includes($route.name)"
					variant="success"
					class="float-right"
					@click="$router.push({ name: 'ManualOrder'});">
					{{ translate('create') }}
				</b-button>
			</div>
		</div>
		<hr v-if="!$route.params.distributorId">
		<data-filter
			v-if="!$route.params.distributorId"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						v-if="showUserFilter()"
						class="col-md-4 col-sm-4 col-12">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,pre_distributor,customer,affiliate'}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div
						:class="showUserFilter() ? 'col-md-4 col-sm-4 ' : 'col-md-6 col-sm-6'"
						class="col-12">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
					<div
						:class="showUserFilter() ? 'col-md-4 col-sm-4 ' : 'col-md-6 col-sm-6'"
						class="col-12">
						<div
							class="form-group">
							<label
								for="invoiceId"
								class="label">{{ translate('invoice_id') }}</label>
							<input
								id="invoiceId"
								v-model="filters.invoiceId"
								:placeholder="translate('type_invoice')"
								type="text"
								name="invoiceId"
								class="form-control">
						</div>
					</div>
				</div>
				<div class="row">
					<div
						:class="showUserFilter() ? 'col-md-4' : 'col-md-6'"
						class="col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="status"
								class="label">{{ translate('order_status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in $route.name === 'ManualOrdersLogs' ? manualOrderStatuses : statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div
						:class="showUserFilter() ? 'col-md-4' : 'col-md-6'"
						class="col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="false && dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="false && dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
					<div
						v-if="showCountryFilter"
						:class="false && dateRange === dateRanges.custom ? 'col-sm-4 col-12' : 'col-sm-4 col-12' ">
						<div
							v-if="countries !== null"
							class="form-group">
							<label
								for="country"
								class="label">{{ translate('country') }}</label>
							<select
								id="country"
								v-model="filters.country"
								:disabled="!countries.length || staffCountry"
								name="country"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="'' || undefined">
										{{ translate('all') }}
									</option>
									<template v-if="countries.length">
										<option
											v-for="country in countries"
											:key="country.attributes.code"
											:value="country.attributes.code">
											{{ translate(country.attributes.code.toLowerCase()) }}
										</option>
									</template>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-form
			v-if="admin.includes(this.$user.details().type) && $route.name === 'OrdersPickup'"
			@submit.prevent="goToDetails">
			<div class="row no-gutters d-flex justify-content-end mb-3">
				<div class="col">
					<input
						ref="orderId"
						v-model="orderId"
						:placeholder="translate('order_id')"
						type="text"
						class="form-control">
				</div>
				<div class="pl-1 col-auto">
					<button
						type="submit"
						class="btn btn-primary w-100">
						{{ translate('view_thing', { name: translate('detail') }) }}
					</button>
				</div>
			</div>
		</b-form>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('order_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('invoice_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type) || $route.name === 'OrdersReferred'"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('user_id') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							v-if="$route.name === 'OrdersReferred' || admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_type')">-->
							{{ translate('user_type') }}
							<!--<sort field="user_type" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('username')">-->
							{{ translate('username') }}
							<!--<sort field="username" />-->
						</th>
						<th
							v-if="$route.name === 'OrdersReferred' || admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('full_name')">-->
							{{ translate('full_name') }}
							<!--<sort field="full_name" />-->
						</th>
						<th
							v-if="!['OrdersReferred', 'OrdersPickup'].includes($route.name)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('ship_to')">-->
							{{ translate('ship_to') }}
							<!--<sort field="ship_to" />-->
						</th>
						<th
							v-if="!['OrdersReferred', 'OrdersPickup'].includes($route.name)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('tracking_number')">-->
							{{ translate('tracking_number') }}
							<!--<sort field="tracking_number" />-->
						</th>
						<th
							v-if="!['OrdersReferred', 'OrdersPickup'].includes($route.name)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('tracking_status')">-->
							{{ translate('tracking_status') }}
							<!--<sort field="tracking_status" />-->
						</th>
						<th
							v-if="$route.name !== 'OrdersReferred'"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('payment_method')">-->
							{{ translate('payment_method') }}
							<!--<sort field="payment_method" />-->
						</th>
						<th
							v-if="$route.name === 'OrdersReferred' && !admin.includes($user.details().type)"
							class="border-top-0">
							{{ translate('is_personal_sponsored') }}
						</th>
						<th
							class="border-top-0 text-right">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('total_amount')">-->
							{{ translate('total') }}
							<!--<sort field="total_amount" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('status')">-->
							{{ translate('order_status') }}
							<!--<sort field="status" />-->
						</th>
						<th
							v-if="showCountryFilter"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!-- @click="sortByField('country')" -->
							{{ translate('country') }}
							<!-- <sort field="country" /> -->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('date')">-->
							{{ translate('order_date') }}
							<!--<sort field="date" />-->
						</th>
						<th
							class="border-top-0">
							{{ translate('order_paid_date') }}
						</th>
						<th class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle text-center">
							{{ item.id }}
							<i
								v-if="item.attributes.is_fraud"
								v-b-tooltip.hover
								:title="translate('fraudulent_order')"
								class="fa fa-info-circle"
								style="margin-left:5px; margin-top:2px; color:#f86c6b" />
							<i
								v-if="item.attributes.is_invisible"
								v-b-tooltip.hover
								:title="translate('invisible_order')"
								class="fa fa-eye-slash"
								style="margin-left:5px; margin-top:2px; color:gray" />
							<i
								v-if="item.attributes.is_gift_order"
								class="fa fa-gift text-success" />
							<template v-if="item.attributes.verification_status !== null">
								<br>
								<span
									class="badge"
									:class="`badge-${verificationStatusesColor[item.attributes.verification_status || 'valid']}`">
									{{ translate(`verification_status_${item.attributes.verification_status || 'valid'}`) }}
								</span>
							</template>
						</td>
						<td class="align-middle">
							{{ item.attributes.invoice_id }}
						</td>
						<td
							v-if="admin.includes($user.details().type) || $route.name === 'OrdersReferred'"
							class="align-middle">
							<span
								v-if="item.attributes.user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="$route.name === 'OrdersReferred' || admin.includes($user.details().type)"
							class="align-middle">
							{{ translate(item.attributes.user_type) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td
							v-if="$route.name === 'OrdersReferred' || admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.full_name }}
						</td>
						<td
							v-if="!['OrdersReferred', 'OrdersPickup'].includes($route.name)"
							class="align-middle">
							{{ item.attributes.ship_to }}
						</td>
						<td
							v-if="!['OrdersReferred', 'OrdersPickup'].includes($route.name)"
							class="align-middle text-left">
							<template v-if="item.attributes.sub_orders && item.attributes.sub_orders.length > 0">
								<span
									:id="`${item.id}-tracking-numbers`"
									class="pointer text-primary">
									{{ translate('view_tracking_numbers') }}
									<i class="fa fa-info-circle" />
								</span>
								<b-popover
									:target="`${item.id}-tracking-numbers`"
									triggers="hover"
									custom-class="order-trackings-popover"
									boundary-padding="0"
									:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'left'">
									<div class="p-1 pb-2">
										<template>
											<div class="pbv-popover">
												<div
													class="table-responsive mb-0">
													<table class="table text-nowrap">
														<thead>
															<tr>
																<th class="border-top-0">
																	{{ translate('order_id') }}
																</th>
																<th class="border-top-0">
																	{{ translate('invoice_id') }}
																</th>
																<th class="border-top-0">
																	{{ translate('order_status') }}
																</th>
																<th class="border-top-0">
																	{{ translate('tracking_number') }}
																</th>
																<th class="border-top-0">
																	{{ translate('tracking_status') }}
																</th>
																<td class="border-top-0">
																	{{ translate('options') }}
																</td>
															</tr>
														</thead>
														<tbody>
															<tr
																v-for="(subOrder) in item.attributes.sub_orders"
																:key="subOrder.id">
																<td class="align-middle text-center">
																	{{ subOrder.id }}
																</td>
																<td class="align-middle">
																	{{ subOrder.invoice_id }}
																</td>
																<td class="align-middle">
																	{{ translate(subOrder.status.toLowerCase()) }}
																</td>
																<td class="align-middle">
																	<template v-if="Object.keys(subOrder.tracking).length !== 0">
																		<template
																			v-if="shippingMethodsLinkable.includes(subOrder.tracking.carrier_code)">
																			<a
																				:href="shippingMethodsLinks[subOrder.tracking.carrier_code](subOrder.tracking.tracking_number)"
																				target="_blank">
																				{{ subOrder.tracking['tracking_number'] }}
																			</a>
																		</template>
																		<template v-else>
																			{{ subOrder.tracking['tracking_number'] }}
																		</template>
																	</template>
																</td>
																<td class="align-middle">
																	{{ translate(subOrder.tracking_status) }}
																</td>
																<td class="align-middle">
																	<b-button
																		v-b-tooltip.hover
																		:title="translate('view_details')"
																		class="btn mx-1 bg-primary-alt"
																		@click="viewDetails(subOrder.id)">
																		<i
																			class="fa fa-eye"
																			aria-hidden="true" />
																	</b-button>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</template>
									</div>
								</b-popover>
							</template>
							<template v-else-if="item.attributes.tracking.length !== 0">
								<template
									v-if="shippingMethodsLinkable.includes(item.attributes.tracking['carrier_code'])">
									<a
										:href="shippingMethodsLinks[item.attributes.tracking['carrier_code']](item.attributes.tracking['tracking_number'])"
										target="_blank">
										{{ item.attributes.tracking['tracking_number'] }}
									</a>
								</template>
								<template v-else>
									{{ item.attributes.tracking['tracking_number'] }}
								</template>
							</template>
						</td>
						<td
							v-if="!['OrdersReferred', 'OrdersPickup'].includes($route.name)"
							class="align-middle">
							{{ translate(item.attributes.tracking_status) }}
						</td>
						<td
							v-if="$route.name !== 'OrdersReferred'"
							class="align-middle">
							<span v-if="item.attributes.wallet_split">{{ translate('cashondelivery') }} + </span>
							{{ translate(item.attributes.payment_method) }}
							<span v-if="item.attributes.payment_method === 'free'"> - {{ translate(item.attributes.order_type) }}</span>
						</td>
						<td
							v-if="$route.name === 'OrdersReferred' && !admin.includes($user.details().type)"
							class="align-middle">
							{{ translate(`${!!item.attributes.is_personal_sponsored}`) }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_amount }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td
							v-if="showCountryFilter"
							class="align-middle">
							<img
								v-if="item.attributes.is_pickup_order"
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(pickupStoreIsoCode.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${pickupStoreIsoCode.toUpperCase()}.png`)"
								:alt="translate(pickupStoreIsoCode.toLowerCase())">
							<img
								v-else
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle">
							{{ item.attributes.date.date ? $moment(item.attributes.date.date).format(dateFormat) : $moment(item.attributes.date).format(dateFormat) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.paid_at ? $moment(item.attributes.paid_at.date).format(dateFormat) : '' }}
						</td>
						<td class="align-middle text-center">
							<b-button
								v-if="validateBinaryButton(item)"
								v-b-tooltip.hover
								:title="translate($route.name === 'OrdersReferred' ? 'view_sponsor_binary_tree' : 'view_binary_tree')"
								class="btn mx-1 bg-primary-alt"
								@click="$router.push({ name: 'BinaryTree', params: { userId: $route.name === 'OrdersReferred' ? item.attributes.sponsor_info.id : item.attributes.user_id } })">
								<i
									class="fa fa-network-wired"
									aria-hidden="true" />
							</b-button>
							<router-link
								v-if="item.attributes.wallet_address !== '' && $route.name !== 'OrdersReferred'"
								:to="{ name: 'CryptoPaymentsDetails', params: { wallet_address: item.attributes.wallet_address } }"
								target="_blank">
								<b-button
									v-b-tooltip.hover
									:title="translate('crypto_payment_details')"
									class="btn mx-1 bg-primary-alt">
									<i
										class="fab fa-bitcoin"
										aria-hidden="true" />
								</b-button>
							</router-link>
							<b-button
								v-if="!admin.includes($user.details().type) && item.attributes.can_retry_payment"
								v-b-tooltip.hover.top
								:disabled="retryingPayment"
								:title="translate('retry_payment')"
								class="btn mx-1 bg-primary-alt"
								@click="retryPayment(item.id)">
								<i
									v-if="orderRetried !== item.id"
									class="fa fa-refresh"
									aria-hidden="true" />
								<template v-else>
									<i class="fa fa-fw fa-spinner fa-pulse" />
								</template>
							</b-button>
							<b-button
								v-b-tooltip.hover
								:title="translate('view_details')"
								class="btn mx-1 bg-primary-alt"
								@click="viewDetails(item.id)">
								<i
									class="fa fa-eye"
									aria-hidden="true" />
							</b-button>
							<router-link
								v-if="!admin.includes($user.details().type) && item.attributes.can_payment_method_change"
								:to="{ name: $route.name === 'OrdersPickup' ? 'PSChangePaymentMethodMain' : 'ChangePaymentMethodMain', params: { order_id: item.id } }">
								<b-button
									v-b-tooltip.hover.top
									:title="translate('change_payment_method')"
									class="btn mx-1 bg-primary-alt">
									<i
										class="fa fa-money-bill-wave"
										aria-hidden="true" />
								</b-button>
							</router-link>
							<b-button
								v-if="$can('orders', 'invoice') && item.attributes.invoice"
								v-b-tooltip.hover
								:title="translate('download')"
								:disabled="loading"
								class="btn mx-1 bg-primary-alt"
								@click="download(item.id, item.attributes.invoice_id)">
								<i
									class="fa fa-download"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>

import { PAGINATION } from '@/settings/RequestReply';
import {
	Grids, Tooltip, Users, Countries, PaymentMethods, TrackingStatus, OrderTypes,
	OrderVerifications,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import DataFilter from '@/components/DataFilter';
import { YMDHMS_FORMAT, DATE_RANGES as dateRanges, V_CALENDAR_CONFIG } from '@/settings/Dates';
import Order from '@/util/Order';
import { SEARCH_USERS } from '@/config/endpoint';
import {
	distributor, customerRoles, superAdmin, admin,
} from '@/settings/Roles';
import {
	ORDER_STATUSES as statuses,
	MANUAL_ORDER_STATUSES,
	ORDER_VERIFICATION_STATUSES_COLORS,
} from '@/settings/Statuses';
import { RegisterAsCustomer } from '@/router/Modules/Pages';
import Country from '@/util/Country';
import ExternalPayments from '@/util/Purchase';
import { SHIPPING_METHODS_LINKS, SHIPPING_METHODS_LINKABLE } from '@/settings/Shipping';
import { PICKUP_STORE } from '@/settings/Country';
import { VALIDATE_PERSONAL_DOCUMENT_COUNTRIES } from '@/settings/AddressBook';
import WindowSizes from '@/mixins/WindowSizes';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Orders',
	messages: [
		Grids, Tooltip, Users, Countries,
		PaymentMethods, TrackingStatus, OrderTypes,
		OrderVerifications,
	],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect, WindowSizes],
	data() {
		return {
			invoice: new Order(),
			orders: new Order(),
			alert: new this.$Alert(),
			countryData: new Country(),
			endpoint,
			superAdmin,
			statuses,
			manualOrderStatuses: MANUAL_ORDER_STATUSES,
			dateFormat: YMDHMS_FORMAT,
			dateRanges,
			calendarConfig: V_CALENDAR_CONFIG,
			copied: false,
			registerCustomerLink: `${document.location.origin}/${RegisterAsCustomer.path}`,
			distributor,
			customerRoles,
			admin,
			shippingMethodsLinks: SHIPPING_METHODS_LINKS,
			shippingMethodsLinkable: SHIPPING_METHODS_LINKABLE,
			externalPayments: new ExternalPayments(),
			orderRetried: 0,
			orderId: null,
			pickupStoreIsoCode: PICKUP_STORE,
			validateShippingDocument: VALIDATE_PERSONAL_DOCUMENT_COUNTRIES,
			verificationStatusesColor: ORDER_VERIFICATION_STATUSES_COLORS.users,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		errors() {
			return this.orders.data.errors;
		},
		pagination() {
			return this.orders.data.pagination;
		},
		data() {
			try {
				const { data } = this.orders.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		countries() {
			try {
				return this.countryData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		showCountryFilter() {
			return this.$route.name !== 'OrdersPickup' && ((this.$route.name === 'OrdersReferred' && this.$user.details().type === this.distributor) || this.admin.includes(this.$user.details().type));
		},
		retryingPayment() {
			return this.externalPayments.data.loading;
		},
		staffCountry() {
			if (this.admin.includes(this.$user.details().type)) {
				return this.$user.details().country !== undefined;
			}
			return false;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	watch: {
		copied(value) {
			if (value) {
				setTimeout(() => { this.copied = false; }, 4000);
			}
		},
	},
	mounted() {
		const { name } = this.$route;
		if (this.$route.params.distributorId) this.$route.query.userId = this.$route.params.distributorId;
		if (name === 'OrdersAll' || name === 'UsersMainOrders') {
			this.orders.getOrders();
		} else if (name === 'OrdersReferred') {
			this.orders.getReferredOrders(this.$user.details().id);
		} else if (name === 'OrdersPickup') {
			this.orders.getPickupOrders();
		} else if (name === 'ManualOrdersLogs') {
			this.orders.getManualOrders();
		} else {
			this.orders.getOrders();
		}

		if (this.showCountryFilter) {
			this.countryData.getCountries({ also_pickup_stores: true });

			if (this.staffCountry) {
				this.filters.country = this.countryCode;
			}
		}

		if (this.$refs.orderId) {
			this.$refs.orderId.focus();
		}
	},
	methods: {
		download(orderId, invoiceId) {
			const config = {
				allowEscapeKey: false,
				allowOutsideClick: false,
			};

			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), config);
			this.invoice.getInvoice(orderId).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] });
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('invoice_id')} ${invoiceId}.pdf`);
				this.$el.appendChild(link);
				link.click();
				this.alert.close();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			const { name } = this.$route;
			if (name === 'OrdersAll' || name === 'UsersMainOrders') {
				this.orders.getOrders(options);
			} else {
				this.orders.getReferredOrders(this.$user.details().id, options);
			}
		},
		viewDetails(orderId) {
			this.$router.push({ name: 'OrdersDetails', params: { order_id: orderId } });
		},
		goToDetails() {
			try {
				const orderId = this.orderId.trim();
				if (orderId) {
					this.viewDetails(orderId);
					this.orderId = '';
				}
			} catch (error) {
				// do nothing
			}
		},
		showUserFilter() {
			return this.$route.name === 'OrdersReferred' || this.admin.includes(this.$user.details().type);
		},
		validateBinaryButton(item) {
			if (this.$route.name === 'OrdersReferred') {
				return this.admin.includes(this.$user.details().type) && item.attributes.sponsor_info.type === distributor;
			}

			return this.admin.includes(this.$user.details().type) && item.attributes.type === distributor;
		},
		retryPayment(orderId) {
			// const options = {
			// 	idempotency_id: this.createUuid(),
			// 	// eslint-disable-next-line no-underscore-dangle
			// 	sift_session_id: window._sift_session_id,
			// };
			this.orderRetried = orderId;
			this.externalPayments.retryExternalPayment(orderId)
				.then((response) => {
					window.location.href = response.response.redirect_url;
				}).catch(() => this.alert.toast('error', this.translate('default_error_message')))
				.finally(() => { this.orderRetried = 0; });
		},
	},
};
</script>
<style>
.order-trackings-popover {
	max-width: 600px;
}
.order-trackings-popover > .popover-body {
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.order-trackings-popover {
		max-width: 80%;
	}
}
</style>
